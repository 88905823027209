import React, { useState } from "react";
import "./Header.css";
import { BiMenuAltRight } from "react-icons/bi";
import OutsideClickHandler from "react-outside-click-handler";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';


const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);

  const getMenuStyles = (menuOpened) => {
    if (document.documentElement.clientWidth <= 800) {
      return { right: !menuOpened && "-100%" };
    }
  };

  return (
    <section className="h-wrapper">
      <div className="flexCenter paddings innerWidth h-container">
        <Link to='/'>
        <img src="/images/logo.png" alt="company logo" width={80} />
        </Link>
        

        <OutsideClickHandler
          onOutsideClick={() => {
            setMenuOpened(false)
          }}
        >
          <div className="flexCenter h-menu" style={getMenuStyles(menuOpened)}>
           <Link to="/#products-section">Products</Link>
           <Link to="/#value-section">Our Value</Link>
            <NavLink to='gallery'>Gallery</NavLink>
            {/* <NavLink to="about">About Us</NavLink> */}
            <Link 
            className="button" 
            to="/#contact-section"
            style={{
              color: "white"
            }}>
              Contact
            </Link>
          </div>
        </OutsideClickHandler>

        <div
          className="menu-icon"
          onClick={() => setMenuOpened((prev) => !prev)}
        >
          <BiMenuAltRight size={30} />
        </div>
      </div>
    </section>
  );
};

export default Header;
