import React, { useState } from "react";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { MdOutlineArrowDropDown } from "react-icons/md";
import "./Value.css";

const AccordionListItem = ({ item, index }) => {
 
  const [expand, setExpand] = useState(false);

  return (
    <AccordionItem className={`accordionItem ${expand}`} uuid={index}>
      <AccordionItemHeading>
        <AccordionItemButton className="flexCenter accordionButton">
          <AccordionItemState>
            {({ expanded }) =>
              expanded ? setExpand("expanded") : setExpand("collapsed")
            }
          </AccordionItemState>
          <div className="flexCenter icon">{item.icon}</div>
          <span className="primaryText">{item.heading}</span>
          <div className="flexCenter icon">
            <MdOutlineArrowDropDown size={20} />
          </div>
        </AccordionItemButton>
      </AccordionItemHeading>

      <AccordionItemPanel>
        <div className="secondaryText">{item.detail}</div>
      </AccordionItemPanel>
    </AccordionItem>
  );
};

export default AccordionListItem;
