import React from "react";
import "./Home.css";
import Hero from "../Hero/Hero";
import Categories from "../Categories/Categories";
import Value from "../Value/Value";
import Contact from "../Contact/Contact";

const Home = () => {
  return (
    <div className="Home">
      <div className="hero-head-container">
        <div className="white-gradient" />
        {/* <Header /> */}
        <Hero />
      </div>
      <Categories />
      <Value />
      <div className="foot">
        <div className="white-gradient" />
        <Contact />
      </div>
    </div>
  );
};

export default Home;
