import React from "react";
import "./Contact.css";
import { MdCall } from "react-icons/md";
import { MdEmail } from "react-icons/md";
import { MdPhonelinkRing } from "react-icons/md";
import { BsFillChatDotsFill } from "react-icons/bs";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Contact = () => {
  const cell = "0735673466";
  const phone = "0614120352";
  const email = "sales@perezprojects.co.za";

  const handleAlert = (type) => {
    alert(`${type} copied`);
  };

  return (
    <section className="c-wrapper" id="contact-section">
      <div className="paddings innerWidth flexCenter c-container">
        <div className="c-head flexColCenter">
          <span className="redText" style={{ fontSize: "2.5rem" }}>
            Our Contacts
          </span>
          <span className="secondaryText" style={{ color: "var(--secondary)" }}>
            We are always ready to help by providing the best services for you.
            <br />
            Gone are the days where we thought of buying furniture for
            exhibitions, private parties or any other event given any scale.
          </span>
        </div>

        {/* Right side */}
        <div className="flexColStart c-right">
          <div className="flexColStart contactModes">
            {/* First row */}
            <div className="flexStart row">
              <div className="flexColCenter mode">
                {/* First mode */}
                <div className="flexStart">
                  <div className="flexCenter c-icon">
                    <MdCall size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Phone</span>
                    <span className="secondaryText">061 412 0352</span>
                  </div>
                </div>
                <CopyToClipboard
                  text={phone}
                  onCopy={() => handleAlert("Phone number ")}
                >
                  <div className="flexCenter button">Call Now</div>
                </CopyToClipboard>
              </div>

              {/* Second mode */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter c-icon">
                    <MdPhonelinkRing size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Cell</span>
                    <span className="secondaryText">073 567 3466</span>
                  </div>
                </div>
                <CopyToClipboard
                text={cell}
                onCopy={() => handleAlert("Cell number ")}
                >
                  <div className="flexCenter button">Call Now</div>
                </CopyToClipboard>
              </div>
            </div>

            {/* Second Row */}
            <div className="flexStart row">
              {/* Third mode */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter c-icon">
                    <BsFillChatDotsFill size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">WhatApp</span>
                    <span className="secondaryText">061 412 0352</span>
                  </div>
                </div>
                  <a 
                  href="https://wa.me/c/27614120352" 
                  className="flexCenter button"
                  style={{textDecoration: "none"}}>Chat Now</a>
              </div>

              {/* Fourth mode */}
              <div className="flexColCenter mode">
                <div className="flexStart">
                  <div className="flexCenter c-icon">
                    <MdEmail size={25} />
                  </div>
                  <div className="flexColStart detail">
                    <span className="primaryText">Email</span>
                    <span className="secondaryText">
                      sales@perezprojects.co.za
                    </span>
                  </div>
                </div>
                <CopyToClipboard
                  text={email}
                  onCopy={() => handleAlert("Email ")}
                >
                  <div className="flexCenter button">Email Now</div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
