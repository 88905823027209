import React from 'react'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import "swiper/css"
import './Categories.css'
import data from '../../utils/slider.json'
import { sliderSettings } from '../../utils/common';
import topBlob from '../SvgIcon/top-blob.svg'

const Categories = () => {
  return (
    <section 
        className="r-wrapper" 
        id='products-section'
        style={{ 
            background: `url(${topBlob})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
         }}>
    
      <div className="paddings innerWidth r-container">
        <div className="r-head flexColStart">
            <span className='redText'>Best Choices</span>
            <span className='primaryText'>Popular Services</span>
        </div>

        <Swiper {...sliderSettings}>
            <SliderButtons/>
            {
                data.map((card, i) =>(
                    <SwiperSlide key={card.id}>
                        <div className='flexColStart r-card'>
                            <img src={`/images${card.image}`} alt="home" />
                            <span className='primaryText'>{card.name}</span>
                            <span className='secondaryText'>{card.detail}</span>
                        </div>
                    </SwiperSlide>
                ))
            }
        </Swiper>
      </div>
    </section>
  )
}

export default Categories

const SliderButtons = () =>{
    const swiper = useSwiper();
    return(
        <div className="flexCenter r-buttons">
            <button onClick={() => swiper.slidePrev()}>&lt;</button>
            <button onClick={() => swiper.slideNext()}>&gt;</button>
        </div>
    )
}