import { HiShieldCheck } from "react-icons/hi";
import { MdCancel, MdAnalytics } from "react-icons/md";
const data = [
  {
    id: 1,
    icon: <HiShieldCheck/>,
    heading: "Commitment to customer service",
    detail:
      "We always work to over deliver to our clients. We believe communication is key; we endeavor to ensure our clients are well informed and receive regular updates. Clear, Concise and Friendly communication is vital. ",
  },
  {
    id: 2,
    icon: <MdCancel />,
    heading: "We Work with Integrity",
    detail:
      "We operate on a foundation of trust, integrity and respect which are fundamental for the success of our business and personal relations. We do what we say and say what we do. We deliver.",
  },
  {
    id: 3,
    icon: <MdAnalytics />,
    heading: "Best price on the market",
    detail:
      "We offer quality services at an affordable prices. Our main goal is customer satisfaction, which we obtain through market orientation and the allocation of ongoing service and support.",
  },
];
export default data;