import React, { useState } from "react";
import './Gallery.css'
import data from '../../utils/imgGallery.json'
import gBlob from '../SvgIcon/gallery-blob.svg'
import { MdOutlineClose } from "react-icons/md";

const Gallery = () => {

  const [modal, setModal] = useState(false);
  const [tempImgSrc, setTempImgSrc] = useState('');

  const getImg = (imgSrc) =>{
    setTempImgSrc(imgSrc);
    setModal(true)
  }

  return (
    <>
     {/* <Header /> */}
    <section 
    className="g-wrapper"
    style={{ 
      background: `url(${gBlob})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
   }}>
      <div className="flexCenter paddings innerWidth g-container">
        <h1
          className="redText"
          style={{
            textAlign: "center",
            fontSize: "2rem",
          }}
        >
          View Our Gallery
        </h1>
        
        <div className="g-class">
        <div className={ modal ? "modal open" : "modal"}>
          <img src={`/images${tempImgSrc}`} alt=""/>
          <MdOutlineClose onClick={() => setModal(false)} />
        </div>

          {
            data.map((item, index) =>{
              return(
                <div className="pics" key={index} onClick={() => getImg(item.imgSrc)}>
                  <img src={`/images${item.imgSrc}`} alt="" style={{ width: "100%"}} />
                </div>
              )
            })
          }
        </div>
      </div>
    </section>
    </>
  );
};

export default Gallery;
