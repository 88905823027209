import React from "react";
import {
  Accordion,
} from "react-accessible-accordion";
import './Value.css'
import data from '../../utils/accordion'
import AccordionListItem from './AccordionListItem';
import bottomBlob from '../SvgIcon/bottom-blob.svg'

const Value = () => {
  return (
    <section 
      className="v-wrapper"
      id="value-section"
      style={{ 
        background: `url(${bottomBlob})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
     }}>
      <div className="paddings innerWidth flexCenter v-container">
        {/* Left Side */}
        <div className="v-left">
          <div className="image-container">
          <img src="/images/img-21.jpg" alt="hero" />
          </div>
        </div>
        {/* Right Side */}
        <div className="flexColStart v-right">
          <span className='redText'>Our Value</span>
          <span className='primaryText'>Value We Give To You</span>
          <span className='secondaryText'>
            We are always ready to help by providing the best services for you.
            <br />
            Gone are the days where we thought of buying furniture for exhibitions, private parties or any other event given any scale.
          </span>

          <Accordion
            className='accordion'
            allowMultipleExpanded={false}
            preExpanded={[0]}
          >
            {data.map((item, i) =>{
                return(
                  <AccordionListItem
                      item={item}
                      key={i}
                      index={i} />)
              })
            }
          </Accordion>
        </div>
      </div>
    </section>
  )
}

export default Value