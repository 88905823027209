import React from "react";
import "./Hero.css";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <section className="hero-wrapper">
      <div className="paddings innerWidth flexCenter hero-container">
        {/* Left Side */}
        <div className="flexColCenter hero-left">
          <div className="hero-title">
            <div className="red-circle" />
            <motion.h1 
            initial={{ y: "2rem", opacity: 0 }}
            animate={{y:0, opacity:1}}
            transition={{
                duration: 2,
                type: "spring"
            }}
            >
              WELCOME TO <br /> PEREZ MANAGEMENT <br /> AND PROJECTS
            </motion.h1>
          </div>
          <div className="flexColCenter hero-des">
            <span>Find a variety of tents that suit your events</span>
            <span>
              Forget all the difficulties in finding perfect tents for you
            </span>
          </div>
        </div>
        {/* Right Side */}
        {/* <div className="flexCenter hero-right">
          <motion.div 
          className="image-container"
          initial={{x: "7rem", opacity:0}}
          animate={{x: 0, opacity:1}}
          transition={{
            duration:2,
            type: "spring"
          }}
          >
            <img src="/images/hero-image.jpeg" alt="hero" />
          </motion.div>
        </div> */}
      </div>
    </section>
  );
};

export default Hero;
