import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <section className="f-wrapper">
      <div className="paddings innerWidth flexCenter f-container">
        <div className="f-head flexCenter">
            <span style={{color: "var(--secondary)"}}>Copyright &copy; Designed by CSP Technologies {new Date().getFullYear()}</span>
        </div>
      </div>
    </section>
  );
};

export default Footer;
